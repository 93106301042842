<template>
  <transition name="fade">
    <div class="response-search">
      <div class="response-search__wrapper">
        <Section limiter>
          <Row justify="between" v-if="$viewport.desktop">
            <Column class="grid-2">
              <span class="response-search__result">Результаты поиска</span>
            </Column>
            <Column class="grid-10">
              <Column class="response-search__list"
                      v-if="$search.data.length > 0">
                <div class="row response-search__list-item"
                     v-for="item in $search.data"
                     :key="item.uinqid">
                  <Column v-if="item.images[0]">
                    <router-link :to="item.link">
                      <div class="response-search__list-item-image-holder">
                        <img class="response-search__list-item-image"
                             :src="item.images[0]"
                             alt/>
                      </div>
                    </router-link>
                  </Column>
                  <Column class="grid-6">
                    <router-link :to="item.link">
                      <p class="response-search__list-item-type"
                         v-if="item.type">
                        {{$type(item.type.name ? item.type.name : item.type)}}</p>
                      <p class="response-search__list-item-title" v-html="item.title"></p>
                      <p class="response-search__list-item-description"
                         v-html="item.description"></p>
                    </router-link>
                  </Column>
                  <router-link
                      :to="{ path: '/interactive-map?object_id=' + item.id }"
                      class="grid-2 response-search__list-item-map-link"
                      v-if="item.latitude && item.longitude">
                    <Icon viewport="0 0 70 80" xlink="ag-map" style="width: 32px; height: auto"
                      fill="#2a8865" />
                    <div>
                      Показать на карте
                    </div>
                  </router-link>
                </div>
                <Button v-if="$search.links.next"
                        v-on:click.native="nextPage"
                        class="trips-view__more"
                        color="like-link">Показать еще
                </Button>
              </Column>
              <span class="response-search__not-found"
                    v-else>{{notFound}}</span>
            </Column>
          </Row>
          <div class="response-search__result-wrapper" v-else>
            <span class="response-search__result">Результаты поиска</span>
            <div class="response-search__list-item"
                 v-for="item in $search.data"
                 :key="item.uinqid">
              <router-link :to="item.link">
                <p class="response-search__list-item-type"
                   v-if="item.type">
                  {{$type(item.type.name ? item.type.name : item.type)}}</p>
                <p class="response-search__list-item-title" v-html="item.title"></p>
                <p class="response-search__list-item-description" v-html="item.description"></p>
              </router-link>
            </div>
          </div>
        </Section>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ResponseForSearch',
  data() {
    return {
      notFound: 'Мы старались, но ничего не нашли по вашему запросу, попробуйте еще раз!',
    };
  },
  methods: {
    nextPage() {
      this.$store.dispatch('GET_NEXT_REQUEST_SEARCH_FROM_SERVER', {
        params: {
          page: this.$search.links.next,
          count: 6,
        },
      });
    },
  },
};
</script>

<style lang="scss">
  @import './../assets/style/utilits/index';

  .response-search {
    @include position(fixed, $top: 0, $left: 0, $right: 0, $bottom: 0);
    z-index: 1;
    @include size(100%, 100vh);
    background: rgba(0, 0, 0, .58);

    &__wrapper {
      padding: 122px 0 52px;
      background-color: $white;
    }

    &__not-found {
      @include font(16px, normal, 1.5);
    }

    &__result {
      @include font(14px, normal);
      color: rgba(0, 0, 0, .5);
    }

    &__list {
      @include size(100%, calc(100vh - 122px));
      overflow-y: auto;

      &-item {
        padding: 16px 0;

        &-image {
          @include size(100%, 100%);

          &-holder {
            @include size(96px, 96px);
            margin: 0 16px 0 0;
            overflow: hidden;
            border-radius: 5px;
          }
        }

        &-type {
          margin: 0 0 6px;
          @include font(12px, bolder, 1.33, -.1px);
          color: rgba(0, 0, 0, .6);
        }

        &-title {
          margin: 0 0 4px;
          @include font(16px, bold, 1.5);
        }

        &-description {
          margin: 0 0 8px;
          @include font(14px, normal);
        }

        &-map-link {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          align-items: center;
          color: #2a8865;

          > div {
            font-size: 12px;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
</style>
